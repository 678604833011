import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";


const PrivateRoute = ({ element }) => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp > currentTime) {
                return element;  // Token is valid, return the protected route
            }
        } catch (error) {
            console.error("Invalid token", error);
        }
    }
    return <Navigate to="/login" replace />;  // Redirect to login if token is invalid or expired
};

export default PrivateRoute;