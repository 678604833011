import React from 'react';
import { useParams } from 'react-router-dom';

const BusinessDetail = () => {
    const { id } = useParams(); // Get the business ID from the route params

    return (
        <div>
            <h1>Business Detail for ID: {id}</h1>
            {/* Fetch and display business details based on the ID */}
        </div>
    );
};

export default BusinessDetail;
