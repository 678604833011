import React from 'react';

const BusinessModal = ({
                           isOpen,
                           onClose,
                           onSubmit,
                           business,
                           setBusiness,
                           mode
                       }) => {
    if (!isOpen) return null;

    const businessTypes = ['Retail', 'Service', 'Manufacturing', 'Hospitality', 'Construction', 'Other']; // Predefined business types

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBusiness(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 style={{fontFamily:'Inter,sans-serif'}}>{mode === 'edit' ? `Edit: ${business.name}` : 'Create New Business'}</h2>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={business.name || ''}
                            onChange={handleInputChange}
                            required
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Address:</label>
                        <input
                            type="text"
                            name="address"
                            value={business.address || ''}
                            onChange={handleInputChange}
                            required
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Business Type:</label>
                        <select
                            name="type"
                            value={business.type || ''}
                            onChange={handleInputChange}
                            required
                            className="input-field"
                        >
                            <option value="" disabled>Select a type</option>
                            {businessTypes.map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Zip Code:</label>
                            <input
                                type="text"
                                name="zipCode"
                                value={business.zipCode || ''}
                                onChange={handleInputChange}
                                required
                                className="input-field"
                            />
                        </div>
                        <div className="form-group">
                            <label>Phone Number:</label>
                            <input
                                type="text"
                                name="phoneNumber"
                                value={business.phoneNumber || ''}
                                onChange={handleInputChange}
                                required
                                className="input-field"
                            />
                        </div>
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={business.email || ''}
                            onChange={handleInputChange}
                            required
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Website:</label>
                        <input
                            type="text"
                            name="website"
                            value={business.website || ''}
                            onChange={handleInputChange}
                            required
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Contact Name:</label>
                        <input
                            type="text"
                            name="contactName"
                            value={business.contactName || ''}
                            onChange={handleInputChange}
                            required
                            className="input-field"
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Contact Phone Number:</label>
                            <input
                                type="text"
                                name="contactPhone"
                                value={business.contactPhone || ''}
                                onChange={handleInputChange}
                                required
                                className="input-field"
                            />
                        </div>
                    </div>
                    <div className="modal-button-group">
                        <button type="submit" className="submit-button">
                            {mode === 'edit' ? 'Update' : 'Create'}
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="cancel-button"
                        >
                            Cancel
                        </button>
                    </div>

                    <div className="link-button-group">
                        <button className="redirect-button">
                            <a href="https://cbisaas.b2clogin.com/cbisaas.onmicrosoft.com/b2c_1_react_client_sign_up_and_sign_in/oauth2/v2.0/authorize?client_id=26ff0e3e-6708-45bf-b284-318c4b035ebc&scope=openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Flive.clear-business-insight.org&client-request-id=64c017ef-6e58-43f2-8c0c-41dba8291236&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.39.0&client_info=1&code_challenge=2a-O6boiVXzu9xHqRGSQ-SaRGj_vc9lNQnzRKHPkJZo&code_challenge_method=S256&nonce=ce53e648-080e-4481-8092-401fd84184fb&state=eyJpZCI6ImNhN2JlNDQ0LTRmNDYtNGFkNS1iMDU0LWI0MWZiOTJmNjQ0YiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D"
                               target="_blank" rel="noopener noreferrer">
                                Go to Clear Business Insight
                            </a>
                        </button>
                        <button className="redirect-button">
                            <a href="https://cacbydesign.com/" target="_blank" rel="noopener noreferrer">
                                Go to CAC By Design
                            </a>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BusinessModal;