import axios from "axios";

// Get the API URL from the environment variable
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllBusinesses = async () => {
    try {
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/GetBusinesses`);
        return response.data;
    } catch (error) {
        console.error('Error fetching businesses:', error.response || error.message);
    }
}

export const deleteBusiness = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/BusinessEntity/Business/DeleteBusiness/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const createBusiness = async (data) => {
    try {
        const response = await axios.post(`${apiUrl}/BusinessEntity/Business/CreateBusiness`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }

}

export const updateBusiness = async (id, data) => {
    try {
        const response = await axios.put(`${apiUrl}/BusinessEntity/Business/UpdateBusiness/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;  // Ensure to throw the error for handling in the component
    }
};
export const searchBusinesses = async (name, zipCode) => {
    // Initialize an array to hold query parameters
    const queryParams = [];

    // Add parameters conditionally
    if (name) {
        queryParams.push(`name=${encodeURIComponent(name)}`);
    }

    if (zipCode) {
        queryParams.push(`zipCode=${encodeURIComponent(zipCode)}`);
    }

    // Construct the query string
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    try {
        // Send the request to the backend
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/SearchBusinesses/search${queryString}`);
        return response.data; // Return the search results
    } catch (error) {
        console.error("Error searching businesses:", error);
        throw error; // Re-throw the error to handle it in the component
    }
};
