import React, { useState, useEffect } from 'react';
import {getAllBusinesses, deleteBusiness, updateBusiness, createBusiness} from '../services/BusinessServices';
import '../styles/common.scss';
import Navbar from "../components/Navbar";
import BusinessModal from "./BusinessModal";

const BusinessGrid = () => {
    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentBusiness, setCurrentBusiness] = useState({
        name: '',
        address: '',
        phoneNumber: '',
        email: '',
        contactName: '',
        contactPhone: ''
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getAllBusinesses()
            .then((businesses) => {
                setData(businesses);
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    const handleCreateClick = () => {
        setIsEdit(false);
        setCurrentBusiness({
            name: '',
            address: '',
            phoneNumber: '',
            email: '',
            contactName: '',
            contactPhone: ''
        });
        setShowModal(true);
    };

    const handleCreate = () => {
        createBusiness(currentBusiness)
            .then(() => {
                fetchData();
                setShowModal(false);
            })
            .catch((error) => {
                console.error("Error creating business:", error);
            });
    };


    const handleEditClick = (id) => {
        const businessToEdit = data.find(business => business.id === id);
        setCurrentBusiness(businessToEdit);
        setIsEdit(true);
        setShowModal(true);
    };

    const handleEdit = () => {
        updateBusiness(currentBusiness.id, currentBusiness)
            .then(() => {
                fetchData();
                setShowModal(false);
            })
            .catch((error) => {
                console.error("Error updating business:", error);
            });
    };

    const handleDelete = (id) => {
        deleteBusiness(id)
            .then(() => {
                fetchData();
            })
            .catch((error) => {
                console.error("Error deleting business:", error);
            });
    };

    return (
        <div className="App" style={{ backgroundColor: '#606c38', color: '#fefae0' }}>
            <Navbar />
            <main>
                <h3 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Inter, sans-serif' }}>
                    Business Directory
                </h3>
<div> <button
    onClick={handleCreateClick}
    className="create-business-button"
    style={{
        padding: '8px',
        backgroundColor: '#dda15e',
        color: '#fff',
        border: 'none',
        fontFamily: 'Inter,sans-serif',
        borderRadius: '4px',
        cursor: 'pointer'
    }}
>
    Create Business
</button>
</div>

                <BusinessModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onSubmit={isEdit ? handleEdit : handleCreate}
                    business={currentBusiness}
                    setBusiness={setCurrentBusiness}
                    mode={isEdit ? 'edit' : 'create'}
                />

                <div style={{ overflowX: 'auto', marginTop: '20px' }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        color: 'black'
                    }}>
                        <thead>
                        <tr>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>ID</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Name</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Type</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Address</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Zip Code</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Phone Number</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Website</th>
                            <th style={{
                                borderBottom: '1px solid #ddd',
                                padding: '8px',
                                textAlign: 'right'
                            }}>Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((business) => (
                            <tr key={business.id}>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.id}</td>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.name}</td>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.type}</td>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.address}</td>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.zipCode}</td>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.phoneNumber}</td>
                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{business.website}</td>
                                <td style={{borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'right'}}>
                                    <button
                                        onClick={() => handleEditClick(business.id)}
                                        style={{
                                            marginRight: '10px',
                                            padding: '5px 10px',
                                            backgroundColor: '#283618',
                                            color: '#fefae0',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(business.id)}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#dc3545',
                                            color: '#fff',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </main>

            <footer style={{
                backgroundColor: '#283618',
                fontFamily: 'Inter, sans-serif',
                color: 'white',
                textAlign: 'center',
                padding: '10px 0'
            }}>
                <p>&copy; {new Date().getFullYear()} Your Local Trusted Expert. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default BusinessGrid;
