import './App.css';
import Home from "./pages/home";
import BusinessGrid from "./pages/BusinessGrid";
import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BusinessDetail from "./pages/BusinessDetailPage";
import PrivateRoute from './components/PrivateRoute';
import Login from "./pages/Login";
import Signup from "./pages/SignUp";

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <ErrorBoundary>
                        <Routes>
                            {/* Public routes for login and signup */}
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />

                            {/* Protected routes */}
                            <Route path="/" element={<PrivateRoute element={<Home />} />} />
                            <Route path="/businesses" element={<PrivateRoute element={<BusinessGrid />} />} />
                            <Route path="/business/:id" element={<PrivateRoute element={<BusinessDetail />} />} />
                        </Routes>
                    </ErrorBoundary>
                </header>
            </div>
        </Router>
    );
}

export default App;