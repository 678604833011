import React, { useState, useEffect, useRef } from 'react';
import {
    getAllBusinesses,
    searchBusinesses
} from '../services/BusinessServices';
import '../styles/common.scss';
import Navbar from "../components/Navbar";
import { useNavigate } from 'react-router-dom';


const BusinessGrid = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getAllBusinesses();
    };

    const handleSearch = () => {
        let name = '';
        let zipCode = '';

        if (!isNaN(searchTerm.trim())) {
            zipCode = searchTerm.trim();
        } else {
            name = searchTerm.trim();
        }

        searchBusinesses(name, zipCode)
            .then((filteredBusinesses) => {
                setSearchResults(filteredBusinesses);
                setShowDropdown(true);
            })
            .catch((error) => {
                console.error("Error searching businesses:", error);
                setSearchResults([]);
                setShowDropdown(false);
            });
    };

    const handleSelectBusiness = (business) => {
        setShowDropdown(false);
        navigate(`/business/${business.id}`);
        setSearchTerm('');
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="App" style={{ backgroundColor: '#606c38', color: '#fefae0' }}>
            <Navbar />
            <main>
                <h3  style={{textAlign: 'center', marginBottom: '20px', fontFamily: 'Inter, sans-serif', color:' #283618'}}>
                    Helping you find the closest expert in your area
                </h3>
                {/* Search Bar and Image Container */}
                <div className="search-image-container" style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '300px',
                    marginBottom: '20px',
                    padding: '20px',
                    paddingLeft: '70px',
                    background: 'linear-gradient(90deg, #333, #606c38)', /* Gradient background */
                    borderRadius: '8px'
                }}>
                    {/* Search Bar Section */}
                    <div className="search-bar-container" style={{ position: 'relative', display: 'flex', paddingLeft: '70px', flexDirection: 'column', width: '80%', maxWidth: '600px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <input
                                type="text"
                                placeholder="Search for a business..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{ padding: '8px', marginRight: '8px', fontFamily: 'Inter, sans-serif', width: '100%' }}
                            />
                            <button onClick={handleSearch} style={{
                                padding: '8px',
                                backgroundColor: '#dda15e',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontFamily: 'Inter, sans-serif',
                            }}>
                                Search
                            </button>
                        </div>
                        {showDropdown && searchResults.length > 0 && (
                            <div ref={dropdownRef} style={{
                                position: 'absolute',
                                top: '100%', /* Position it directly below the search bar */
                                left: 0,
                                backgroundColor: '#fff',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                maxHeight: '150px',
                                overflowY: 'auto',
                                width: '100%', /* Match the search bar width */
                                zIndex: 1000,
                                fontFamily: 'Inter, sans-serif',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' /* Add a subtle shadow */
                            }}>
                                {searchResults.map(business => (
                                    <div
                                        key={business.id}
                                        onClick={() => handleSelectBusiness(business)}
                                        style={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                            color: '#000',
                                            transition: 'background-color 0.2s ease-in-out' /* Smooth transition */
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'} /* Hover effect */
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'} /* Reset background */
                                    >
                                        {business.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </main>

            <footer style={{
                backgroundColor: '#283618',
                fontFamily: 'Inter, sans-serif',
                color: 'white',
                textAlign: 'center',
                padding: '10px 0'
            }}>
                <p>&copy; {new Date().getFullYear()} Your Local Trusted Expert. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default BusinessGrid;