import React from 'react';
import '../styles/navbar.scss';
import {Link} from "react-router-dom";

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <a href="/">Your Local Trusted Expert</a>
            </div>
            <ul className="navbar-links">
                <li><a href="/">Home</a></li>
                <Link to="/businesses">Businesses</Link>
                <li><a href="/about">About</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/contact">Log In</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;